// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "Daze-module--CarrouselWrapper2--mkJ3b";
export var DescriptionWrapper = "Daze-module--DescriptionWrapper--qFaR6";
export var DownloaderWrapper = "Daze-module--DownloaderWrapper---hPui";
export var EventWrapper = "Daze-module--EventWrapper--jcTQl";
export var ImageWrapper = "Daze-module--ImageWrapper--vWJ6Y";
export var ImagesWrapper = "Daze-module--ImagesWrapper--qLE8d";
export var MobileTile = "Daze-module--MobileTile--asP5k";
export var PdpWrapper = "Daze-module--PdpWrapper--DHH0e";
export var PhotosWrapper = "Daze-module--PhotosWrapper--HQfjL";
export var TitleWrapper = "Daze-module--TitleWrapper--lolon";
export var Wrapper = "Daze-module--Wrapper--ntJ8J";